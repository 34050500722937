import React, { useEffect, useState } from "react"
import * as ScrollMagic from "scrollmagic"
import { TweenMax, TimelineMax } from "gsap"
import Img from "gatsby-image"
import StyledProjectPage from "../styles/StyledProjectPage"
import StyledProjectImageContainer from "../styles/StyledProjectImageContainer"
import StyledProjectTextContainer from "../styles/StyledProjectTextContainer.jsx"
import StyledLandingBackground from "../styles/StyledLandingBackground"

import BorderBoxes from "../components/BorderBoxes"
import PageSwiper from "../components/PageSwiper"
import Header from "../components/Header"
import Tech from "../components/Tech"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NextProject from "../components/NextProject"
import AwwwardsBanner from "../components/AwwwardsBanner"
import StyledNextProject from "../styles/StyledNextProject"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"
import { navigate, Link } from "gatsby"
if (typeof ScrollMagicPluginGsap == "function") {
  ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)
}

const Project = ({ data, pageContext, location }) => {
  const [nextProjectText, setNextProjectText] = useState([])
  const [currentProject, setCurrentProject] = useState({})
  const [nextProject, setNextProject] = useState(null)
  const [swiperLeft, setSwiperLeft] = useState("0")
  const [prevPath, setPrevPath] = useState("")
  const { tech, stats } = data.datoCmsCommonField
  const projects = data.allDatoCmsProject.nodes

  const keys = { 37: 1, 38: 1, 39: 1, 40: 1 }

  const preventDefault = e => {
    e = e || window.event
    if (e.preventDefault) e.preventDefault()
    e.returnValue = false
  }

  const preventDefaultForScrollKeys = e => {
    if (keys[e.keyCode]) {
      preventDefault(e)
      return false
    }
  }

  const disableScroll = () => {
    if (window.addEventListener)
      document.addEventListener("wheel", preventDefault, { passive: false })
    window.addEventListener("DOMMouseScroll", preventDefault, false)
    document.addEventListener("wheel", preventDefault, { passive: false }) // Disable scrolling in Chrome
    window.onwheel = preventDefault // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault // older browsers, IE
    window.ontouchmove = preventDefault // mobile
    document.onkeydown = preventDefaultForScrollKeys
  }

  const createNextProjectText = () => {
    let arr = []
    for (let i = 0; i < 4; i += 1) {
      arr.push(<NextProject num={i + 1} />)
    }
    setNextProjectText(arr)
  }

  const setCurrentAndNextProject = async () => {
    for (let i = 0; i < projects.length; i += 1) {
      if (projects[i].path === pageContext.urlPath) {
        setCurrentProject(projects[i])
        if (i < projects.length - 1) {
          setNextProject(projects[i + 1])
          return projects[i + 1]
        } else {
          setNextProject(projects[0])
          return projects[0]
        }
      }
    }
  }

  const enableScroll = () => {
    window.removeEventListener("DOMMouseScroll", preventDefault, false)
    document.removeEventListener("wheel", preventDefault, { passive: false }) // Enable scrolling in Chrome
    window.onmousewheel = document.onmousewheel = null
    window.onwheel = null
    window.ontouchmove = null
    document.onkeydown = null
  }

  const lastAnimate = next => {
    disableScroll()
    toggleOpacity(false)
    TweenMax.to(".next-project-image", 0.7, {
      y: 0,
      onComplete: () => {
        enableScroll()
        setTimeout(() => {
          navigate(`/${next.path}`, {
            state: { prevPath: window.location.pathname },
          })
        }, 100)
      },
    })
  }

  const animateNextProject = next => {
    const image = document.querySelector(".next-project-image")
    const tl = new TimelineMax({
      onComplete: () => {
        if (window.innerWidth > 640) {
          image.classList.add("anim-complete")
          lastAnimate(next)
        }
      },
    })
    const controller = new ScrollMagic.Controller()
    tl.to(".next-project--inner", 3, { x: -1000 })
    tl.from(".next-project-image", 3, { scale: 0.6, y: 100 }, "-=2.5")
    tl.to(".next-project--inner", 2, { opacity: 0 }, "-=3")
    tl.to(".next-button--mobile", 2, { opacity: 1 }, "-=2")

    const scene = new ScrollMagic.Scene({
      triggerElement: ".anim-trigger",
      triggerHook: "onLeave",
      duration: "100%",
    })
      .setPin(".anim-trigger")
      .setTween(tl)
      .addTo(controller)
  }

  const toggleOpacity = bool => {
    const overlay = document.querySelector(".next-project-overlay")
    overlay.classList[bool ? "add" : "remove"]("show")
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setPrevPath(window.location.pathname)
      createNextProjectText()
      enableScroll()
      setCurrentAndNextProject().then(next => {
        setTimeout(() => {
          animateNextProject(next)
        }, 500)
      })
      setTimeout(() => {
        setSwiperLeft("100%")
      }, 50)
    }
  }, [])
  return (
    <Layout>
      <Header siteTitle="" />
      <SEO />
      {location.state && location.state.prevPath === "/" && (
        <PageSwiper swiperLeft={swiperLeft} />
      )}
      {pageContext.urlPath === "doctorwho" && <AwwwardsBanner />}
      <StyledProjectPage bgColor={pageContext.bgColor}>
        <StyledProjectImageContainer>
          <Img
            className="project--image"
            fluid={data.datoCmsProject.projectImage.fluid}
            alt={pageContext.urlPath}
          />
          <BorderBoxes />
        </StyledProjectImageContainer>
        <StyledProjectTextContainer>
          <p>{pageContext.year}</p>
          <h1>{pageContext.title}</h1>
          <h2>{pageContext.subtitle}</h2>
          {
            pageContext.urlPath === 'lfw' ? (
              <React.Fragment>
              <p>Results:</p>
              <ul>
                <li>27,000 sessions on the first day of events</li>
                <li>50,000 unique visitors to the site over the course of the weekend</li>
                <li>147,000 page views during the event weekend</li>
                <li>2.40 minutes average dwell time spent by visitors over the event weekend</li>
              </ul>
              </React.Fragment>

            ) : ''
          }
          <div className="project-text">
            <div className="project-text-left">
              <Tech cms={tech} url={pageContext.urlPath} />
            </div>
            <div className="project-text-right">
              <h2>Design: </h2>
              <p>{data.datoCmsProject.designer}</p>
            </div>
          </div>
        </StyledProjectTextContainer>
        <div className="project-image--fullwidth">
          <Img
            fluid={data[pageContext.urlPath].childImageSharp.fluid}
            alt={`${pageContext.title} full width image`}
          />
        </div>

        <StyledNextProject className="anim-trigger">
          <div className="next-project--inner">{nextProjectText}</div>
          {nextProject && (
            <div
              className="next-project-image"
              onMouseEnter={() => toggleOpacity(true)}
              onMouseLeave={() => toggleOpacity(false)}
            >
              <Link
                to={`/${nextProject ? nextProject.path : ""}`}
                state={{ prevPath }}
              >
                <div className="next-project-overlay">
                  <div className="next-circle">
                    <p>Next Project</p>
                  </div>
                </div>
              </Link>

              <img
                src={nextProject.projectImage.fluid.src}
                alt={pageContext.urlPath}
              />
              <BorderBoxes />
            </div>
          )}
          <Link
            to={`/${nextProject ? nextProject.path : ""}`}
            state={{ prevPath }}
          >
            <div className="next-button--mobile">Next -></div>
          </Link>
        </StyledNextProject>
      </StyledProjectPage>
    </Layout>
  )
}

export const query = graphql`
  query($urlPath: String!) {
    datoCmsProject(path: { eq: $urlPath }) {
      projectImage {
        fluid {
          src
        }
      }
      designer
    }
    allDatoCmsProject {
      nodes {
        projectTitle
        path
        projectImage {
          fluid {
            src
          }
        }
      }
    }
    dotsBg: file(relativePath: { eq: "dotsBg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    doctorwho: file(relativePath: { eq: "doctorwho.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    destinationcanada: file(relativePath: { eq: "destination.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    forevermark: file(relativePath: { eq: "forevermark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    adidas: file(relativePath: { eq: "adidas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lfw:  file(relativePath: { eq: "lfw.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    datoCmsCommonField {
      tech
      stats
    }
  }
`

export default Project
