import styled from "styled-components"

const StyledProjectImageContainer = styled.div`
  width: 46rem;
  height: 29rem;
  position: relative;
  margin-top: 9rem;
  & .project--image {
    z-index: 9998;
    position: relative;
    border: 1px solid black;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    width: 600px;
    height: 389px;
    margin-top: 7rem;
  }
  @media only screen and (max-width: 640px) {
    width: 300px;
    height: 187px;
    margin-top: 8rem;
  }
`

export default StyledProjectImageContainer
