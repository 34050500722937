import React, { useEffect, useState } from "react"
import powers from "../utils/powers.json"

const Tech = ({ cms, url }) => {
  const [skills, setSkills] = useState([])
  const techList = () => {
    let arr = []
    const result = powers.find(({ project }) => project === url)
    result.skills.map(para => arr.push(<p>{para}</p>))
    setSkills(arr)
  }

  useEffect(() => {
    techList()
  }, [])
  return (
    <div>
      <h2>{cms}:</h2>
      <div className="skill__container">{skills}</div>
    </div>
  )
}

export default Tech
