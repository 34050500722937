import styled from "styled-components"
const StyledProjectTextContainer = styled.div`
  width: 46rem;
  margin-top: 6rem;
  & h1 {
    font-weight: bold;
    font-size: 4.5rem;
    line-height: 5.625rem;
    -webkit-text-stroke: 0.05vw #000;
    -webkit-text-fill-color: rgba(255, 255, 255, 1);
    will-change: -webkit-text-fill-color, -webkit-text-stroke;
    text-shadow: 0.2rem 0.2rem #000000f5;
    font-family: "Pano";
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      font-size: 55px;
    }
  }
  & li {
    font-family: "Pano Trial";
    font-style: normal;
    font-weight: normal;
    font-size: 0.8125rem;
  }
  & h2 {
    font-family: "Pano";
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 2.5rem;
  }
  .project-text {
    display: flex;
    width: 100%;
  }

  .project-text-left,
  .project-text-right {
    width: 50%;
  }
  .skill__container {
    width: 100%;
    max-height: 20rem;
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    & p {
      width: 50%;
      font-size: 0.8125rem;
    }
  }
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    width: 600px;
  }
  @media only screen and (max-width: 640px) {
    width: 300px;
    margin-top: 3rem;
    & .project-text {
      flex-direction: column;
      & .project-text-left,
      .project-text-right {
        width: 100%;
      }
    }
    & h1 {
      font-size: 30px;
      margin-top: 0;
      line-height: 55px;
    }
    & h2 {
      font-size: 14px;
      line-height: 37px;
    }
    & .skill__container {
      max-height: 13rem;
      p {
        font-size: 11px;
      }
    }
  }
`

export default StyledProjectTextContainer
