import styled from "styled-components"

const StyledProjectPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: ${props => props.bgColor}; */
  flex-direction: column;
  position: relative;
  & h1 {
    margin-top: 2.5rem;
  }
  & p {
    font-family: 'Pano Trial';
    font-weight: 300;
    font-size: 0.8125rem;
  }
  & .project-image--fullwidth {
    margin: 5rem 0;
    width: 100%;
    height: 36rem;
    overflow: hidden;
    & img {
      object-fit: cover;
    }
    @media only screen and (max-width: 1024px) {
      width: 100%;
    height: initial;
    margin: 4rem 0;
    }
   
  }
   @media only screen and (max-width: 640px) {
      & p {
        font-size: 11px;
        width: 100%;
        line-height: 15px;
      }
      & h1 {
        margin-top: 0;
      }
    }
  
  
`

export default StyledProjectPage
