import React from "react"
import StyledNextProject from "../styles/StyledNextProject"

const NextProject = ({ num }) => {
  return (
    <>
      <div className="black-dot"></div>
      <h2>{num % 2 === 0 ? "Next Project" : "Scroll Down"}</h2>
    </>
  )
}

export default NextProject
