import styled from "styled-components"

const StyledNextProject = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  position: relative;
  & .next-button--mobile {
    width: 239px;
    height: 50px;
    text-align: center;
    box-shadow: 5px 5px black;
    line-height: 50px;
    font-family: "Pano";
    font-style: normal;
    font-weight: bold;
    position: absolute;
    top: 600px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    border: 1px solid black;
  }

  & h2 {
    font-family: "Pano";
    font-style: normal;
    font-weight: bold;
    font-size: 5rem;
    line-height: 5rem;
    margin-bottom: 0;
  }
  & .next-project--inner {
    width: 400vw;
    height: 5rem;
    line-height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4rem 0 4rem 0;
    & h2 {
      font-size: 3.125rem;
      line-height: 5rem;
      margin-bottom: 0;
      width: 31.875rem;
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 640px) {
        font-size: 29px;
        line-height: 29px;
        width: 335px;
      }
    }
  }
  & .black-dot {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: #000000;
    position: relative;
    margin: 0 4rem;
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      height: 1.5rem;
      width: 1.5rem;
      margin: 0 1rem;
    }
    @media only screen and (max-width: 640px) {
      height: 1rem;
      width: 1rem;
      margin: 0 1rem;
    }
  }

  & .next-project-image {
    width: 46rem;
    height: 29rem;
    position: absolute;
    top: 9rem;
    left: 50%;
    margin: 0 auto 4rem auto;
    transform: translateX(-50%);
    & .next-project-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      background: rgba(0, 0, 0, 0.7);
      transition: opacity 0.3s ease;
      z-index: 2222;
      &.show {
        opacity: 1;
      }
      & .next-circle {
        width: 15rem;
        height: 15rem;
        border-radius: 50%;
        background: white;
        text-align: center;
        line-height: 15rem;
        & p {
          margin: 0;
          padding: 0;
          font-family: "Pano";
          font-weight: bold;
        }
      }
    }

    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: 1111;
      position: relative;
      border: 1px solid black;
    }
    &.anim-complete {
      transition: transform 0.5s cubic-bezier(0.55, 0.04, 0.27, 1.26);
      transform: translate(-50%, 0%) translate3d(0px, 0px, 0px) scale(1, 1) !important;
    }
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      width: 600px;
      height: 389px;
    }
    @media only screen and (max-width: 640px) {
      width: 300px;
      height: 187px;
    }
  }
  @media only screen and (max-width: 1024px) {
    & .next-button--mobile {
      display: inline-block;
      opacity: 0;
    }
    .next-project-image {
      top: 7rem;
    }
  }
  @media only screen and (max-width: 640px) {
    .next-project-image {
      top: 8rem;
    }
    & .next-button--mobile {
      top: 450px;
    }
  }
`

export default StyledNextProject
